import React from "react"

import * as styles from "./blog-video.module.css"

const BlogVideo = ({ videoId, videoTitle, ...props }) => {
    if (videoId != null) {
        return (
            <div className={styles.frameWrapper}>
                <iframe className={styles.frame}
                src={"https://www.youtube.com/embed/" + videoId}
                title={videoTitle}
                width="903"
                height="508"
                loading="lazy"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                />
            </div>
        )
    }
    else {
        return (
            <div></div>
        )
    }
}

export default BlogVideo
