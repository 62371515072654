import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import get from "lodash/get"
import Video from "../components/blog-video"

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, "data.contentfulBlogPost")

    return (
      <Layout
        pageTitle={post.title}
        SEODescription={post.description.description}
        headerOpt={3}
      >
        <div className="pageWrapper">
          <div className="articleWrapper">
            <h1 className="section-headline">{post.title}</h1>
            <p>
              By: {post.author.name} | {post.publishDate} 
              </p>
              <Video videoId={post.youtubeId} title={post.title}></Video> 
            <div
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
        <hr/>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query blogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      author { name }
      description {
        description
      }
      youtubeId
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
